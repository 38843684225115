angular.module("aerosApp")
    .factory('addRogueUnitModal', ["$uibModal", function ($uibModal) {
        function open(orgInfo) {
            var modal = $uibModal.open({
                size: "md",
                resolve: {
                    orgInfo: function () {return orgInfo;}
            },
                templateUrl: "/static/templates/project/add-rogueunit-modal.html",
                controller: "AddRogueUnitModalCtrl"
            });
            return modal.result;
        }

        return {
            open: open
        };
    }])
    .controller("AddRogueUnitModalCtrl", ["$scope", "$uibModalInstance", "aerosApi", "orgInfo", "Notification", function ($scope, $uibModalInstance, aerosApi, orgInfo, Notification) {

        $scope.newRogueModel = {
            serialNumber: "",
            name: "",
            description: ""
        };

        $scope.addRogueUnit = function () {
            var newRogue = {};
            newRogue.serialNumber = $scope.newRogueModel.serialNumber;
            newRogue.name = $scope.newRogueModel.name;
            newRogue.description = $scope.newRogueModel.description;
            aerosApi.addRogueUnit(orgInfo.id, newRogue)
                .success(function () {
                    $uibModalInstance.close();
                    Notification.success("Rogue Unit (" + newRogue.serialNumber + ") has been added to " + orgInfo.name);
                })
                .error(function (err) {
                    Notification.error("Rogue Unit not added. " + err.message);
                });
        };
    }]);